import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID } from '@angular/core'
import { _privacyPolicy } from 'src/app/router/route-data'
import { RouterService } from '../../router/router.service'
import { isPlatformBrowser } from '@angular/common'
import { CookieConsentService } from '../../shared/services/cookie-consent.service'

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss']
})
export class CookieBarComponent implements OnInit {
  showCookie = true
  _privacyPolicy = _privacyPolicy

  constructor(
    private routerService: RouterService,
    @Inject(PLATFORM_ID) private platformId:  InjectionToken<Record<string, unknown>>,
    private cookieConsentService: CookieConsentService
  ) {}

  ngOnInit(): void {
    this.checkCookie()
  }

  private checkCookie(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    try {
      const getCookieValue = localStorage.getItem('jb_pp')
      if (getCookieValue !== '1') {
        // show the cookie banner every time a user visits the page!
        window['ga-disable-UA-49878150-2'] = true
        this.showCookie = true
        return
      }
    } catch (e) {
      window['ga-disable-UA-49878150-2'] = true
    }

    this.showCookie = false
  }

  accept(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.showCookie = false
    this.cookieConsentService.setCookiesAllowed()

    // we need to register the currently open page as a pageview when the user accepts cookies and analytics!
    this.routerService.sendGooglePageview().then(() => {})
  }

  decline(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.cookieConsentService.setCookiesDisallowed()
    this.showCookie = false
  }
}
