<div class="alert cookiealert py-2 px-1 p-sm-3 row" [ngClass]="{ show: showCookie }" role="alert">
  <div class="col-12 col-sm-10">
    Wir benutzen Cookies um die Benutzerfreundlichkeit unserer Website zu verbessern. Außerdem werden Informationen zur Nutzung für Analysen
    an Google übertragen. Google führt diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie bereitgestellt haben oder die
    Google im Rahmen der Nutzung Ihrer Dienste gesammelt hat. Sie können Ihre Zustimmung jederzeit widerrufen. Weitere Details sowie
    Informationen über die verwendeten Daten finden Sie in unserer
    <a title="Datenschutzerklärung anzeigen" [routerLink]="_privacyPolicy">Datenschutzerkl&auml;rung</a>
  </div>
  <div class="col-12 col-sm-2 d-flex flex-shrink-0 flex-row-reverse mt-2 mt-sm-0 flex-sm-column justify-content-around align-items-center">
    <button (click)="accept()" type="button" class="btn btn-primary btn-sm acceptcookies" aria-label="Zustimmen">Zustimmen</button>
    <button (click)="decline()" type="button" class="btn btn-outline-primary declinecookies btn-sm" aria-label="Ablehnen">Ablehnen</button>
  </div>
</div>
