import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

declare let gtag: Function

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>) {}

  setCookiesAllowed(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    try {
      localStorage.setItem('jb_pp', '1')
      localStorage.setItem('jb_pp_ga', '1')
    } catch (e) {
      // NOP
    }

    if (typeof window !== 'undefined') {
      gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'granted'
      })
    }

    window['ga-disable-UA-49878150-2'] = false
  }

  setCookiesDisallowed(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    try {
      localStorage.clear()
      sessionStorage.clear()
    } catch (e) {
      // NOP
    }
    window['ga-disable-UA-49878150-2'] = true
  }
}
