import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  NgZone,
  InjectionToken
} from "@angular/core";
import {
  _companyInfo,
  HeaderState,
  _company,
  _contact,
  _aboutUs,
  _bundles,
  _homepageMv, _homepageBb
} from "src/app/router/route-data";
import { Subscription } from 'rxjs'
import { RouterService } from 'src/app/router/router.service'
import { UserService } from 'src/app/shared/services/user/user.service'
import { States, User } from "src/app/shared/data/types";
import { isPlatformBrowser } from '@angular/common'
import { StateSelectService } from '../../shared/services/state-select.service'
import {AnimationOptions, LottieTransferState} from "ngx-lottie";
import {AnimationItem} from "lottie-web";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  // Routes
  _companyInfo = _companyInfo
  _aboutUs = _aboutUs
  _contact = _contact
  _company = _company
  _bundles = _bundles

  // HeaderState
  stateSub: Subscription
  headerStateSub: Subscription
  showLogin = false
  showForCompanies = false
  company = false
  faq = false
  isStateMv = true

  // Navigation state
  showCompanyNavigation = false
  showSecondaryNavigation = false

  lottieOptions: AnimationOptions = {
    animationData: this.lottieTransferState.get('navigation.json'),
    loop: false,
    autoplay: false
  };

  user: User
  userSubscription: Subscription

  constructor(
    private routerService: RouterService,
    private userService: UserService,
    private elementRef: ElementRef,
    private stateSelectService: StateSelectService,
    private ngZone: NgZone,
    private lottieTransferState: LottieTransferState,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.lottieOptions = {
        path: '/assets/animations/navigation.json',
        loop: false,
        autoplay: false
      };
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.showCompanyNavigation) {
        this.toggleCompanyNavigation()
      }

      if (this.showSecondaryNavigation) {
        this.toggleSecondaryNavigation()
      }
    }
  }

  ngOnInit() {
    this.stateSub = this.stateSelectService.state$.subscribe((state) => {
      this.isStateMv = state === States.mv
    })

    // HeaderState
    this.headerStateSub = this.routerService.$headerState.subscribe((headerState) => {
      this.showLogin = false
      this.showForCompanies = false
      this.company = false
      this.faq = false

      switch (headerState) {
        case HeaderState.showLogin:
          this.showLogin = true
          break
        case HeaderState.showForCompanies:
          this.showForCompanies = true
          break
        case HeaderState.company:
          this.company = true
          break
        case HeaderState.faq:
          this.faq = true
          break
        default:
          break
      }
    })

    // do not wait on subscriptions for server platform
    if(!isPlatformBrowser(this.platformId)) {
      return
    }
    this.userSubscription = this.userService.$user.subscribe((user) => (this.user = user))
  }

  private animationItem: AnimationItem;
  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  onNavigationEventCompany(): void {
    this.toggleCompanyNavigation()
  }

  onNavigationEvent(): void {
    this.toggleSecondaryNavigation()
  }

  navigateHome() {
    if (!this.stateSelectService.isInitialized) {
      this.stateSelectService.setStateMv()
    }


    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    window.scrollTo(0, 0)
  }

  toggleSecondaryNavigation() {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.showCompanyNavigation = false
    if (this.showSecondaryNavigation) {
      this.showSecondaryNavigation = false
      this.ngZone.runOutsideAngular(() => {
        this.animationItem.playSegments([85, 140], true);
      });
    } else {
      this.showSecondaryNavigation = true
      this.ngZone.runOutsideAngular(() => {
        this.animationItem.playSegments([5, 70], true);
      });
    }
  }

  toggleCompanyNavigation() {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    this.showSecondaryNavigation = false
    if (this.showCompanyNavigation) {
      this.showCompanyNavigation = false
      this.ngZone.runOutsideAngular(() => {
        this.animationItem.playSegments([85, 140], true);
      });
    } else {
      this.showCompanyNavigation = true
      this.ngZone.runOutsideAngular(() => {
        this.animationItem.playSegments([5, 70], true);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.headerStateSub) {
      this.headerStateSub.unsubscribe()
    }
    if (this.stateSub) {
      this.stateSub.unsubscribe()
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe()
    }
  }

  protected readonly _homepageMv = _homepageMv;
  protected readonly _homepageBb = _homepageBb;
}
